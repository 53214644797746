const skillData = [
  {
    title: 'Programming Languages & Frameworks:',
    skill: 'JavaScript, TypeScript, React.js, Next.js, Redux',
    _id: '1'
  },
  {
    title: 'Styling & Design:',
    skill: 'Tailwind CSS, SASS, Material UI, Responsive Design, Cross-browser Compatibility',
    _id: '2'
  },
  {
    title: 'Tools & Platforms:',
    skill: 'Git, GitHub, GitLab, NPM, Yarn, Trello, ProofHub, Jira, Slack, YouTrack, Google Analytics',
    _id: '3'
  },
  {
    title: 'Communication:',
    skill: 'Professional Communication in English, and Bangla',
    _id: '4'
  },
  {
    title: 'Other:',
    skill: 'REST API, ESLint, Agile Workflow Process, Troubleshooting, Business Analysis, AB Testing',
    _id: '5'
  },
];

export function skillsData() {
  return skillData;
}

const pbData = [
  {
    title: 'VJudge Profile',
    link: 'https://vjudge.net/user/saakeeb',
    _id: '1'
  },
  {
    title: 'StopStalk Profile',
    link: 'https://www.stopstalk.com/user/profile/saakeeb',
    _id: '2'
  },
];

export function problemSolvingsData() {
  return pbData;
}