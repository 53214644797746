const data = [
  {
    name: 'Movie Archives',
    github: 'https://github.com/saakeeb/react-m/tree/main/movie-archrive',
    live: 'https://movies-archive.netlify.app/',
    desc: 'React, React Hooks, Lodash, Bootstrap, Animation, and clean, reusable code are used to paginate, sort, and list data with categories in an SPA web application containing movie data.'
  },
  {
    name: 'Blog Lists',
    github: 'https://github.com/saakeeb/blog-list',
    live: 'https://blog-lists.netlify.app/',
    desc: 'Vite, ReactJs, Typescript, Tailwind CSS, Markdown, and Hooks are used in an SPA web application to compose blogs with the crud operation, with title, relevant tags, and blog details.'
  },
  {
    name: 'Landing Page',
    github: 'https://github.com/saakeeb/garden-landing-page',
    live: 'https://saakeeb.github.io/garden-landing-page/',
    desc: 'SASS-powered pixel by pixel responsive landing page. This has a toggle button underneath and is fully responsive with HTML, SASS, Javascript.'
  },
  {
    name: 'Gallary App',
    github: 'https://github.com/saakeeb/nazmusgram',
    live: 'https://nazmusgram.web.app/',
    desc: 'An image-based SPA Web applications using ReactJS, Firebase-Database, FrameJS, Firebase-Hosting, and Framer-Motion can display artwork.'
  }
];

export function projectDatas() {
  return data;
}
