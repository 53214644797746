import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import './Layout.scss';

const Layout = () => {
  return (
    <div className="App" role="main">
      <div className="slice">
        <div className="bg"></div>
        <div className="bg bg2"></div>
        <div className="bg bg3"></div>
      </div>
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="page">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
