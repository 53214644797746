import React, { useState, useRef } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import Loader from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters/AnimatedLetters';
import { sendEmail } from './Config';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useDocumentTitle from '../Hooks/useDocumentTitle';
import { useEffect } from 'react';
import SEO from '../Hooks/SEO';
import { gaLogEvent } from '../React-GA/GA4Tracking';
import './Contact.scss';

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const form = useRef();
  // const gaEventTracker = useAnalyticsEventTracker('Contact Page');
  const position = [23.924701, 90.718935];
  useDocumentTitle('Nazmus - Contact 📩');

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
  }, []);

  return (
    <>
      <div className="container contact-page" role="main">
        <SEO
          description="Send an email to Nazmus Sakib at sakib14april@gmail.com if you need front-end, Javascript, or React development services."
          href="https://nazmus-home.netlify.app/contact"
          title="Nazmus - Contact 📩"
          type="Contact frontend, Javascript, React Developer Nazmus Sakib"
        />
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <div>
            <div className="paragraph-zone">
              <p>
                I'm very ambitious front-end developer looking for a role in established IT company with the opportunity
                to work with the latest technologies on challenging and diverse projects.
              </p>
            </div>
          </div>

          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail} id="myEmailForm">
              <ul>
                <li className="subject">
                  <input placeholder="Name" type="text" name="name" required autoFocus aria-label="Name" />
                </li>
                {/* <li className="half">
                                    <input
                                        placeholder="Email"
                                        type="email"
                                        name="email"
                                        required
                                    />
                                </li> */}
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    defaultValue="Greetings"
                    aria-label="Subject"
                    required
                  />
                </li>
                <li>
                  <textarea placeholder="Message" aria-label="Message" name="message" required></textarea>
                </li>
                <li>
                  <input
                    type="submit"
                    className="flat-button"
                    value="SEND"
                    aria-label="Submit email"
                    onClick={() => gaLogEvent('Contact Page: ', 'Email', 'Email Send Button', 'Email Send Click')}
                  />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="map-container">
          <div className="map-contents">
            <div className="info-map">
              Nazmus Sakib
              <br />
              Narsingdi, Dhaka,
              <br />
              Bangladesh.
              <br />
              <small>
                <a
                  href="mailto:sakib14april@gmail.com?subject=Greetings"
                  onClick={() => gaLogEvent('Contact Page: ', 'Email', 'Email 14april', 'Email Send Click')}
                >
                  sakib14april@gmail.com
                </a>
              </small>
            </div>
            <div className="map-wrap" role="application">
              <MapContainer
                center={position}
                zoom={8}
                aria-label="Map displaying the location of Nazmus Sakib"
                scrollWheelZoom={false}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <Marker position={position} aria-label="Marker indicating the location of Nazmus Sakib">
                  <Popup onClick={() => gaLogEvent('Contact Page: ', 'Map Marker', 'Map Marker Button', 'Map Marker Click')}>
                    Let's have a cup of coffee here.
                  </Popup>
                </Marker>
              </MapContainer>
            </div>
          </div>
        </div>
        <ToastContainer 
          position="top-right"
          autoClose={2000}
        />
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default Contact;
