const data = [
  {
    name: 'Javascript concept, how it works',
    desc: 'Javascript is a high-abstraction language. It does not need to manually manage the resource',
    link: 'https://medium.com/programming-note/its-all-about-the-javascript-concept-df9d51218a43'
  },
  {
    name: 'Learn SASS in a simple way',
    desc: 'SASS = Syntactically Awesome Style Sheet It is an extension of CSS.It was designed by Hampton',
    link: 'https://medium.com/programming-note/simple-way-to-learn-sass-337c14e3f43e'
  },
  {
    name: 'Fecli problem in the recent npm',
    desc: 'NPM stands for Node package manager, originally NPM is a package manager for the',
    link: 'https://medium.com/programming-note/fecli-doesnt-install-in-the-recent-npm-version-43a7391e445d'
  },
  {
    name: 'Permission denied: Could not read from remote repository',
    desc: 'As a programmer, you may encounter many errors. First, you have to read the error message',
    link: 'https://medium.com/programming-note/permission-denied-public-key-fatal-could-not-read-from-remote-repository-e978bcae63b5'
  },
  {
    name: 'Spread operator and rest operator',
    desc: 'The spread operator or spread syntax (…) is a feature of ES6 of JavaScript that gives you access',
    link: 'https://medium.com/programming-note/spread-operator-and-the-rest-operator-a77556af544e'
  },
  {
    name: 'Creating a Firebase Project',
    desc: 'If you ever heard a serverless project. Well there are many projects that the developer has only',
    link: 'https://medium.com/programming-note/creating-firebase-project-4caf98637e3b'
  },
  {
    name: 'How to create react app',
    desc: 'React uses javascript library to create efficient user interfaces of web application in effective',
    link: 'https://medium.com/programming-note/how-to-create-react-app-8f8b0b2e2ef8'
  }
];

export function blogsData() {
  return data;
}
