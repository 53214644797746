// src/EmailJSInitializer.js
import { useEffect } from 'react';
import emailjs from '@emailjs/browser';

const EmailJSInitializer = () => {
  useEffect(() => {
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
    if (publicKey) {
      emailjs.init(publicKey);
    } else {
      console.error('EmailJS public key is not defined');
    }
  }, []);

  return null;
};

export default EmailJSInitializer;
