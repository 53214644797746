import React, { useEffect, useState } from 'react';
import AnimatedLetters from '../AnimatedLetters/AnimatedLetters';
import useAnalyticsEventTracker from '../React-GA/useAnalyticsEventTracker';
import { blogsData } from './BlogsData';
import Blog from './Blog';
import './Blogs.scss';
import anime from 'animejs';
import Loader from 'react-loaders';
import BlogSubscription from './BlogSubscription';
import SEO from '../Hooks/SEO';
import Button from '../Common/Button';

const Blogs = () => {
  const [letterClass, setLetterClass] = useState('text-animate');
  const [visibleProjects, setVisibleProjects] = useState(3);
  const gaEventTracker = useAnalyticsEventTracker('Blogs Page');
  const datas = blogsData();
  // useDocumentTitle('Nazmus - Blogs 📕');

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover');
    }, 3000);
  }, []);

  useEffect(() => {
    const image = document.querySelectorAll('.blogs');
    anime({
      targets: image,
      scaleX: [
        { value: 4, duration: 100, delay: 500, easing: 'easeOutExpo' },
        { value: 1, duration: 900 },
        { value: 4, duration: 100, delay: 500, easing: 'easeOutExpo' },
        { value: 1, duration: 900 }
      ],
      easing: 'easeOutElastic(1, .8)',
      loop: false
    });
  }, []);

  function loadMore() {
    setVisibleProjects(visibleProjects === 3 ? datas.length : 3);
    gaEventTracker('Show More Blogs', 'Blogs Click');
  }

  return (
    <>
      <div className="container blog-page">
        <SEO
          description="Javascript concept, how it works, Learn SASS in a simple way, Fix fecli problem in the recent npm, Permission denied: Could not read from remote repository, Spread operator and rest operator, Creating a Firebase Project, How to create react app, Nazmus Javascript developer, Nazmus React Developer, Nazmus Frontend Developer, Nazmus Sakib"
          href="https://nazmus-home.netlify.app/blogs"
          title="Nazmus - Blogs 📕"
          type="Blogs about  Frontend, Javascript, React Development"
        />
        <div className="text-zone">
          <h1>
            <AnimatedLetters letterClass={letterClass} strArray={['B', 'l', 'o', 'g', 's']} idx={15} />
          </h1>
          <div className="paragraph-zone">
            <p>Subscribe to the newsletter for upcoming blogs.</p>
          </div>
          <div className="blogs">
            {datas.slice(0, visibleProjects).map((data, index) => (
              <div className="blog" key={index}>
                <Blog data={data} />
              </div>
            ))}
          </div>
          <div className="load-more">
            {datas.length > 3 ? (
              <Button
                class_name="flat-button"
                area_label="Show more/less blogs pages"
                handleClick={() => loadMore()}
                text={visibleProjects === 3 ? 'Show More' : 'Show Less'}
              />
            ) : (
              ''
            )}
          </div>
          <div className="news-letter">
            <BlogSubscription />
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  );
};

export default Blogs;
