import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

export const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
export const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
export const publicKey =  process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

export const sendEmail = (e) => {
  e.preventDefault();
  emailjs.sendForm(serviceId, templateId, e.target, publicKey).then(
    (e) => {
      toast.success('Email successfully sent!');
      // window.location.reload(false);
    },
    () => {
      toast.error('Failed to send the message, please try again');
    }
  );
  e.target.reset();
};

