import React, { useEffect } from 'react';
import anime from 'animejs';
import './Logo.scss';
import { useState } from 'react';

const Logo = () => {
  const [svgColor, setSvgColor] = useState('#000000');
  const [animation, setAnimation] = useState({ rotate: '1turn' });

  useEffect(() => {
    const interval = setInterval(() => {
      setSvgColor((color) => (color = '#ffd700'));
      setAnimation({ rotate: [0, '10deg'] });
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const image = document.querySelector('.image-animation');
    anime({
      targets: image,
      translateX: () => {
        return anime.random(0, 100);
      },
      rotate: animation.rotate,
      duration: 3000,
      loop: true,
      easing: 'easeInOutSine'
    });
  }, [animation]);

  return (
    <div className="logo-container">
      <svg
         className="image-animation"
        version="1.0"
        width="158.000000pt"
        height="141.000000pt"
        viewBox="0 0 158.000000 141.000000"
    >
        <g transform="translate(0.000000,141.000000) scale(0.100000,-0.100000)" fill={svgColor} stroke="none">
            <path
                d="M1044 1287 c-29 -16 -14 -36 40 -53 37 -12 62 -27 78 -48 23 -31 23 -32 26 -386 l2 -355 -87 90 c-80 82 -231 243 -433 460 -41 44 -120 126 -175 181 l-100 102 -130 5 c-77 3 -134 1 -140 -5 -11 -10 11 -31 75 -71 61 -40 60 -30 58 -508 -3 -497 3 -462 -92 -498 -36 -13 -51 -24 -51 -37 0 -18 12 -19 180 -22 202 -3 225 0 225 28 0 14 -13 23 -52 36 -34 10 -62 28 -78 47 l-25 31 -3 390 c-1 223 1 393 6 398 19 19 697 -712 817 -882 46 -64 52 -70 83 -70 16 0 22 6 22 23 0 12 0 239 0 505 0 561 -4 536 89 581 40 19 56 32 56 46 0 19 -7 20 -188 20 -103 0 -195 -4 -203 -8z m360 -22 c-4 -8 -14 -15 -23 -15 -30 0 -82 -40 -96 -75 -12 -28 -15 -124 -15 -534 -1 -314 -5 -501 -11 -501 -5 0 -24 21 -42 46 -17 26 -70 92 -117 148 -81 97 -112 132 -246 276 -33 36 -85 92 -115 124 -29 33 -122 129 -205 214 l-151 155 -25 -17 -26 -17 2 -389 1 -388 30 -44 c24 -33 42 -47 80 -60 39 -13 44 -17 25 -18 -14 -1 -28 -2 -32 -1 -4 0 -67 1 -140 2 -109 1 -128 4 -106 13 36 15 68 46 79 75 14 36 22 446 14 689 -7 208 -8 214 -32 243 -14 16 -42 38 -62 48 l-36 19 115 0 115 0 75 -76 c42 -43 136 -142 210 -222 156 -166 378 -403 473 -502 18 -19 57 -25 57 -7 0 5 4 8 9 5 15 -9 14 625 0 676 -17 58 -46 97 -83 114 -36 15 -35 15 64 15 61 0 208 12 217 18 1 0 0 -6 -3 -14z"
            />
        </g>
    </svg>
    </div>
  );
};

export default Logo;
