import React from 'react';
import { Helmet } from 'react-helmet-async';

function SEO({
  title = 'Nazmus Experiences',
  description = "Developer with two years of experience and a four-year electrical and electronics engineering bachelor's degree.Experience in web developing, and providing documentation using JavaScript, HTML5, CSS3, Bootstrap, Tailwind, typescript, ReactJs, Github. Basic experiences in unit testing, VueJs. Employee of the Week recognition history, as well as leadership accomplishments including training trainees at my former workplace Brillmark.",
  name = 'Nazmus Sakib',
  type = 'Portfolio',
  href = 'https://nazmus-home.netlify.app/'
}) {
  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* End standard metadata tags */}
      {/* Facebook tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {/* End Facebook tags */}
      {/* Twitter tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* End Twitter tags */}
      {/* canonical */}
      <link rel="canonical" href={href} />
      {/* canonical */}
    </Helmet>
  );
}

export default SEO;
