const data = [
  {
    companyName: 'Devxhub',
    companyUrl: 'https://devxhub.com/',
    role: 'Frontend Engineer, Full Time - Remote',
    periods: 'July 2023 - Present',
    descriptions: [
      'Increased frontend feature implementation efficiency by 20% through collaboration with backend teams using React.js, Redux, TypeScript, and Tailwind CSS with high quality, scalable apps in production.',
      'Improved code quality by 30% via thorough code reviews and adherence to best practices.',
      'Enhanced intern productivity by 50% by providing mentoring and training.'
    ],
    projects: [''],
    projectsName: ['']
  },{
    companyName: 'Nexuses',
    companyUrl: 'https://nexuses.io/',
    role: 'Frontend Engineer, Contract - Remote',
    periods: 'May 2023 - June 2023',
    descriptions: [
      'Refactored class components to functional components to improve performance and maintainability.',
      'Debugged and resolved bugs ensuring responsiveness using styled-components.',
      'Upgraded Next.js from version 12 to 13, enhancing features and optimizations.'
    ],
    projects: ['https://nexuses.io/'],
    projectsName: ['Nexuses']
  },
  {
    companyName: 'C-Sunshine',
    companyUrl: 'https://www.c-sunshine.com/',
    role: 'Frontend Engineer, Contract - Remote',
    periods: 'August 2022 - February 2023',
    descriptions: [
      'Developed new features and improved UI/UX using React.js, Redux, TypeScript, and Tailwind CSS',
      'Identified and fixed errors using debugging tools to ensure effective code operation post-deployment.',
      'Optimized code and scripts to adhere to project specifications.'
    ],
    projects: [''],
    projectsName: ['']
  },
  {
    companyName: 'Brillmark LLC',
    companyUrl: 'https://www.brillmark.com/',
    role: 'Frontend Developer, Full Time - Remote',
    periods: 'April 2021 - May 2022',
    descriptions: [
      'Added functionality to over 100 websites for more than 20 clients, contributing over 30% of total revenues through AB testing',
      'Utilized Vanilla JavaScript, jQuery, DOM manipulation, Ajax, and JSON to develop and enhance various website functionalities.',
      'Created visually stunning and responsive user interfaces by accurately translating Figma and Adobe XD mockups into pixel-perfect designs using CSS, SASS, and Bootstrap.',
      'Mentored and trained interns, sharing knowledge and expertise to foster their professional growth and development.'
    ],
    projects: ['https://www.skechers.com/', 'https://americanaddictioncenters.org/', 'https://www.springboard.com/'],
    projectsName: ['Skechers', 'American Addiction Centers', 'Springboard']
  }
];

export function experiencesData() {
  return data;
}
