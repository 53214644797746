import React from 'react';

const Button = ({ class_name = '', area_label = '', handleClick = '', text = '', data_title = '' }) => {
  return (
    <button className={class_name} aria-label={area_label} data-title={data_title} onClick={handleClick}>
      {text}
    </button>
  );
};

export default Button;
