import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Sidebar.scss';
import LogoS from '../../assets/images/logo_n.png';
// import LogoSubtitle from '../../assets/images/name_n.png';
import {
  FaUserAlt,
  FaProjectDiagram,
  FaBlogger,
  FaRegEnvelope,
  FaHome,
  FaLinkedin,
  FaGithub,
  FaSkype
} from 'react-icons/fa';
import { BsMedium } from 'react-icons/bs';
import { gaLogEvent } from '../React-GA/GA4Tracking';

const Sidebar = () => {
  // const gaEventTracker = useAnalyticsEventTracker('Sidebar Navigation');

  return (
    <div className="nav-bar">
      <Link
        className="logo"
        to="/"
        onClick={() => gaLogEvent('Sidebar Navigation: ', 'logo', 'Sidebar Logo', 'Sidebar Logo N Click')}
        aria-label="Link to home page"
      >
        <img className="main-logo" src={LogoS} alt="logo" loading="lazy" />
        {/* <img className="sub-logo" src={LogoSubtitle} alt="Nazmus Sakib" /> */}
        <h2>Nazmus</h2>
      </Link>
      <nav>
        <NavLink
          exact="true"
          activeclassname="active"
          to="/"
          aria-label="Link to home page"
          onClick={() => gaLogEvent('Sidebar Navigation: ', 'logo', 'Sidebar Home', 'Sidebar Home Click')}
          end
        >
          <FaHome />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="about-link"
          to="/about"
          aria-label="Link to about page"
          onClick={() => gaLogEvent('Sidebar Navigation: ', 'logo', 'Sidebar About', 'Sidebar About Click')}
        >
          <FaUserAlt />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="projects-link"
          to="/projects"
          aria-label="Link to projects page"
          onClick={() => gaLogEvent('Sidebar Navigation: ', 'logo', 'Sidebar Projects', 'Sidebar Projects Click')}
        >
          <FaProjectDiagram />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="blogs-link"
          to="/blogs"
          aria-label="Link to blogs page"
          onClick={() => gaLogEvent('Sidebar Navigation: ', 'logo', 'Sidebar Blogs', 'Sidebar Blogs Click')}
        >
          <FaBlogger />
        </NavLink>
        <NavLink
          exact="true"
          activeclassname="active"
          className="contact-link"
          to="/contact"
          aria-label="Link to contact page"
          onClick={() => gaLogEvent('Sidebar Navigation: ', 'logo', 'Sidebar Contact', 'Sidebar Contact Click')}
        >
          <FaRegEnvelope />
        </NavLink>
      </nav>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/nazmus-sakib-developer/"
            aria-label="Linkedin Url link"
            onClick={() => gaLogEvent('Sidebar Navigation: ', 'logo', 'Linkedin', 'LinkedIn media')}
          >
            <FaLinkedin />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/saakeeb"
            aria-label="Github Url link"
            onClick={() => gaLogEvent('Sidebar Navigation: ', 'logo', 'Github', 'Github Media')}
          >
            <FaGithub />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://medium.com/@saakeeb"
            aria-label="Medium Url link"
            onClick={() => gaLogEvent('Sidebar Navigation: ', 'logo', 'Medium', 'Medium Media')}
          >
            <BsMedium />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="skype:live:saakeeb"
            aria-label="Skype Url link"
            onClick={() => gaLogEvent('Sidebar Navigation: ', 'logo', 'Skype', 'Skype Media')}
          >
            <FaSkype />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
