import './App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './Components/Layout/Layout';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import Projects from './Components/Projects/Projects';
// import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
// import { useGATiming, useGATimingCallback as callback } from './Components/React-GA/useGATiming';
import Experiences from './Components/Experiences/Experiences';
import Blogs from './Components/Blogs/Blogs';
import { HelmetProvider } from 'react-helmet-async';
import RouteTracker from './Components/React-GA/RouteTracker';
// import { initGA4, logPageView } from './Components/React-GA/GA4Tracking';

// export const mtmUrl = process.env.REACT_APP_MMT_URL;

function App() {
  // const location = useLocation();
  // const GATiming = useGATiming('App Load Performace');

  // useEffect(() => {
  //   initGA4();
  //   logPageView();
  // }, []);

  useEffect(() => {
    const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
    ReactGA.initialize(TRACKING_ID);
    // ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
    // GATiming('Server Latency', 10);
  }, []);

  // useEffect(() => {
  //   GATiming('Server Latency', 10);
  // }, [location, GATiming]);

  // useEffect(() => {
  //   const observer = new PerformanceObserver(callback);
  //   observer.observe({ entryTypes: ['navigation'] });
  //   return () => observer.disconnect();
  // }, []);

  return (
    <HelmetProvider>
      <RouteTracker />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="experiences" element={<Experiences />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="projects" element={<Projects />} />
          <Route path="contact" element={<Contact />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </HelmetProvider>
  );
}

export default App;
